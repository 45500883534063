import { Router } from "@reach/router"
import { navigate } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useHotkeys } from "react-hotkeys-hook"
import PrivateRoute from "../../components/private-route"
import SEO from "../../components/seo"
// import Layout from "../../../components/templates/layout"
import Layout from "../../components/layout/store/Layout"
import StoreGetStartedFinanceView from "../../components/views/dashboard/store/get-started-finance"
import StoreView from "../../components/views/dashboard/store/finance"
import { AccountContext } from "../../context/account"
const FinancePage = () => {
  const { store, getStore } = useContext(AccountContext)
  const [render, setRender] = useState<boolean>(false)
  useEffect(() => {
    if (!store)
      getStore()
        .then(() => {
          setRender(true)
        })
  }, [])

  return (
    <DndProvider backend={HTML5Backend}>
      <Layout>
        {
          setRender && <>
            {!(store?.account?.individual?.verification?.status === 'verified') &&
              <StoreGetStartedFinanceView store={store} />
            }
            {(store?.account?.individual?.verification?.status === 'verified') &&
              <StoreView store={store} />
            }
          </>
        }
      </Layout>
    </DndProvider>
  )
}

export default FinancePage
